<template>
  <Layout tituloPagina="Sistema | Ajustes | Database backup">
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">
          Database backup
        </h4>
        <div>
          <div class="dropdown">
            <a
              class="dropdown-toggle"
              href="javascript: void(0);"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <eva-icon
                name="more-horizontal-outline"
                data-eva-width="20"
                data-eva-height="20"
                :class="{
                  'fill-dark': $store.state.layout.layoutMode == 'light',
                  'fill-white': $store.state.layout.layoutMode == 'dark'
                }"
              ></eva-icon>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  onclick="return false"
                  @click="descargar()"
                  v-if="!bandera_spinner"
                >
                  <i class="mdi mdi-cloud-download-outline text-success"></i>
                  Descargar Zip
                </a>
                <span class="dropdown-item disabled" v-if="bandera_spinner">
                  <i class="mdi mdi-concourse-ci mdi-spin"></i>
                  Descargar Zip
                </span>
              </li>
              <li>
                <a
                  class="dropdown-item" href="#"
                  onclick="return false;" @click="$router.go(-1)"
                >
                  <i class="mdi mdi-close text-dark"></i>
                  Cerrar
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-12">
            <div class="btn-group">
              <button class="btn btn-success" @click="seleccionarTodo()">
                <i class="mdi mdi-check-bold"></i>
                Seleccionar todos
              </button>
              <button class="btn btn-primary" @click="deseleccionarTodo()">
                <i class="mdi mdi-close-thick"></i>
                Deseleccionar todos
              </button>
            </div>
          </div>
        </div>
        <br>
        <div class="row">
          <div v-for="tabla in tablas" :key="tabla" class="col-lg-2 col-sm-6 mb-1">
            <label class="card-radio-label" :title="tabla.nombre">
              <input 
                type="checkbox" class="card-radio-input"
                :checked="tabla.activo" @change="tabla.activo = !tabla.activo"
              />
              <span class="card-radio py-2 text-center text-truncate">
                <i class="d-block h2 mb-2" :class="tabla.icono"></i>
                {{ tabla.nombre }}
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="text-right">
      <div class="btn-group">
        <button class="btn btn-secondary"
          @click="$router.go(-1)">
          <i class="mdi mdi-chevron-left"></i>
          Atras
        </button>
        <button class="btn btn-success" @click="descargar()" :disabled="bandera_spinner">
          <i
            class="mdi"
            :class="!bandera_spinner ? 'mdi-cloud-download-outline' : 'mdi-concourse-ci mdi-spin'"
          ></i>
          Descargar Zip
        </button>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main'
import API from '@/API.js'
export default {
  name: 'Database',
  components: { Layout },
  data() {
    return {
      tablas: [
        {
          id: Math.random(),
          nombre: 'Clientes',
          tabla: 'clientes',
          icono: 'bx bxs-group',
          activo: true
        },
        {
          id: Math.random(),
          nombre: 'Telefonos clientes',
          tabla: 'telefonos_clientes',
          icono: 'bx bxs-phone',
          activo: false
        },
        {
          id: Math.random(),
          nombre: 'Contratos',
          tabla: 'contratos',
          icono: 'bx bxs-archive',
          activo: true
        },
        {
          id: Math.random(),
          nombre: 'Facturas',
          tabla: 'facturas',
          icono: 'bx bxs-file',
          activo: true
        },
        {
          id: Math.random(),
          nombre: 'Serie de facturas',
          tabla: 'series_facturas',
          icono: 'bx bxs-file',
          activo: true
        },
        {
          id: Math.random(),
          nombre: 'Partidas de facturas',
          tabla: 'partidas_facturas',
          icono: 'bx bxs-file',
          activo: false
        },
        {
          id: Math.random(),
          nombre: 'Descuentos partidas facturas',
          tabla: 'descuentos_partidas_facturas',
          icono: 'bx bxs-file',
          activo: false
        },
        {
          id: Math.random(),
          nombre: 'Cargos partidas facturas',
          tabla: 'cargos_partidas_facturas',
          icono: 'bx bxs-file',
          activo: false
        },
        {
          id: Math.random(),
          nombre: 'Cargos de facturas',
          tabla: 'cargos_facturas',
          icono: 'bx bxs-file',
          activo: false
        },
        {
          id: Math.random(),
          nombre: 'Descuentos de facturas',
          tabla: 'descuentos_facturas',
          icono: 'bx bxs-file',
          activo: false
        },
        {
          id: Math.random(),
          nombre: 'Solicitudes de instalación',
          tabla: 'solicitudes_instalacion',
          icono: 'bx bxs-notepad',
          activo: true
        },
        {
          id: Math.random(),
          nombre: 'Telefonos de solicitudes de instalacion',
          tabla: 'telefonos_solicitudes_instalacion',
          icono: 'bx bxs-phone',
          activo: false
        },
        {
          id: Math.random(),
          nombre: 'Pagos',
          tabla: 'abonos',
          icono: 'bx bxs-credit-card-alt',
          activo: true
        },
        {
          id: Math.random(),
          nombre: 'Tickets de soporte',
          tabla: 'tickets_soporte',
          icono: 'bx bxs-report',
          activo: true
        },
        {
          id: Math.random(),
          nombre: 'Cpes',
          tabla: 'cpes',
          icono: 'bx bx-rss',
          activo: true
        },
        {
          id: Math.random(),
          nombre: 'Marcas de Cpes',
          tabla: 'marcas_cpes',
          icono: 'bx bx-book-bookmark',
          activo: false
        },
        {
          id: Math.random(),
          nombre: 'Modelo de Cpes',
          tabla: 'modelos_marcas_cpes',
          icono: 'bx bx-bookmark',
          activo: false
        },
        {
          id: Math.random(),
          nombre: 'Perfiles velocidad Cpes',
          tabla: 'perfiles_velocidad_cpes',
          icono: 'bx bx-food-menu',
          activo: false
        },
        {
          id: Math.random(),
          nombre: 'Planes de servicio',
          tabla: 'planes_servicios',
          icono: 'bx bxs-food-menu',
          activo: false
        },
        {
          id: Math.random(),
          nombre: 'Dispositivos de hogar',
          tabla: 'dhs',
          icono: 'bx bx-wifi',
          activo: true
        },
        {
          id: Math.random(),
          nombre: 'Marcas de DHs',
          tabla: 'marcas_dhs',
          icono: 'bx bxs-book-bookmark',
          activo: false
        },
        {
          id: Math.random(),
          nombre: 'Modelo de DHs',
          tabla: 'modelos_marcas_dhs',
          icono: 'bx bxs-bookmark',
          activo: false
        },
        {
          id: Math.random(),
          nombre: 'Tipos de DHs',
          tabla: 'tipos_dhs',
          icono: 'bx bxs-spreadsheet',
          activo: false
        },
        {
          id: Math.random(),
          nombre: 'Routers',
          tabla: 'routers',
          icono: 'bx bxs-server',
          activo: true
        },
        {
          id: Math.random(),
          nombre: 'ISP routers',
          tabla: 'isp_routers',
          icono: 'bx bxs-ruler',
          activo: false
        },
        {
          id: Math.random(),
          nombre: 'Sitios',
          tabla: 'sitios',
          icono: 'bx bx-map-pin',
          activo: true
        },
        {
          id: Math.random(),
          nombre: 'Puntos de acceso',
          tabla: 'aps',
          icono: 'bx bx-sitemap',
          activo: true
        },
        {
          id: Math.random(),
          nombre: 'Familias / Categorias',
          tabla: 'categorias_productos',
          icono: 'bx bx-layer',
          activo: true
        },
        {
          id: Math.random(),
          nombre: 'Articulos / Productos',
          tabla: 'productos',
          icono: 'bx bx-list-ul',
          activo: true
        },
        {
          id: Math.random(),
          nombre: 'Entradas de almacen',
          tabla: 'entradas_almacen',
          icono: 'bx bxs-cube',
          activo: true
        },
        {
          id: Math.random(),
          nombre: 'Partidas entradas de almacen',
          tabla: 'partidas_entradas_almacen',
          icono: 'bx bxs-cube-alt',
          activo: false
        },
        {
          id: Math.random(),
          nombre: 'Salidas de almacen',
          tabla: 'salidas_almacen',
          icono: 'bx bxs-shopping-bag',
          activo: true
        },
        {
          id: Math.random(),
          nombre: 'Partidas de salidas de almacen',
          tabla: 'partidas_salidas_almacen',
          icono: 'bx bx-shopping-bag',
          activo: false
        },
        {
          id: Math.random(),
          nombre: 'Movimientos de articulos de almacen',
          tabla: 'movimientos_articulos_almacen',
          icono: 'bx bx-shuffle',
          activo: true
        },
        {
          id: Math.random(),
          nombre: 'solicitudes de articulos',
          tabla: 'solicitudes_articulos',
          icono: 'bx bxs-file-blank',
          activo: true
        },
        {
          id: Math.random(),
          nombre: 'partidas de solicitudes de articulos',
          tabla: 'partidas_solart',
          icono: 'bx bx-file-blank',
          activo: false
        },
        {
          id: Math.random(),
          nombre: 'Usuarios',
          tabla: 'usuarios',
          icono: 'bx bxs-user',
          activo: true
        },
        {
          id: Math.random(),
          nombre: 'Hotspots',
          tabla: 'hotspots',
          icono: 'bx bx-station',
          activo: true
        },
        {
          id: Math.random(),
          nombre: 'Perfiles de hotspots',
          tabla: 'perfiles_hotspots',
          icono: 'bx bxs-purchase-tag-alt',
          activo: true
        },
        {
          id: Math.random(),
          nombre: 'Tipos de fichas hotspots',
          tabla: 'tipos_fichas_hotspots',
          icono: 'bx bx-hive',
          activo: false
        },
        {
          id: Math.random(),
          nombre: 'Fichas hotspots',
          tabla: 'fichas_hotspots',
          icono: 'bx bxs-note',
          activo: false
        },
        {
          id: Math.random(),
          nombre: 'Credenciales VPN',
          tabla: 'credenciales_vpn',
          icono: 'bx bxs-shield-alt-2',
          activo: true
        },
        {
          id: Math.random(),
          nombre: 'Archivos',
          tabla: 'archivos',
          icono: 'bx bxs-folder-open',
          activo: false
        }
      ],

      bandera_spinner: false
    }
  },
  methods: {
    seleccionarTodo: function() {
      var self = this 

      self.tablas.forEach(tabla => {
        tabla.activo = true
      })
    },
    deseleccionarTodo: function() {
      var self = this 

      self.tablas.forEach(tabla => {
        tabla.activo = false
      })
    },
    descargar: function() {
      var self = this

      let n = self.tablas.filter( t => { return t.activo == true} ).length
      if(n == 0) {
        iu.msg.warning("No se puede descargar, ya que no se ha seleccionado ningún archivo a descargar")
        return
      }

      let tk = localStorage.getItem('argusblack.token')
      let tablas=''
      self.tablas.forEach(t => {
        if(t.activo) tablas += '&tablas[]='+t.tabla
      })

      window.open(`${API}/sistema/database.zip?_tk=${tk}${tablas}`, '_blank')
    }
  }
}
</script>

<style scoped>

</style>